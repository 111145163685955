<!--
 * @Description: 隐私协议
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-10-08 22:43:37
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/home/privacy.vue
-->
<template>
  <div
    v-html="text"
    style="
      padding: 50px 300px 100px 300px;
      overflow: hidden;
      background-color: #fff;
    "
  ></div>
</template>

<script>
export default {
  data() {
    return {
      text: `
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">我们非常重视保护您的隐私。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">为方便您登录、使用相关服务，以及为您提供更个性化的用户体验和服务，您在使用我们的服务时，我们可能会收集、存储、使用和披露您的相关信息。我们希望通过本隐私声明向您说明，在使用云鼎汇富平台及</span><span>www.aboostify.com </span><span style="font-family: 宋体">服务（统称“本服务”）时，我们如何收集、使用、储存和披露您的信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本隐私声明与您所使用的本服务息息相关，</span> <span style="font-family: 宋体">且重要条款采用粗体字进行标注，希望您仔细阅读。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">阅读过程中，如您有任何疑问，可联系我们的客服咨询，如您不同意协议中的任何条款，您应立即停止访问云鼎汇富平台。阅读协议的过程中，如果您不同意相关协议或其中任何条款约定，您应立即停止使用云鼎汇富平台服务。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">一、</span><span>&nbsp;&nbsp; </span><span style="font-family: 宋体">适用范围</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">本隐私声明中的“信息”特指第二条所收集的信息，本隐私政策不适用于您存储在云鼎汇富平台系统中的数据。您可通过</span><span>&nbsp;&nbsp; </span><span style="font-family: 宋体">《服务协议》</span><span>&nbsp; </span><span style="font-family: 宋体">和</span> <span style="font-family: 宋体">《客户数据常见问题》</span><span>&nbsp; </span><span style="font-family: 宋体">知悉更多关于您存储在云鼎汇富平台系统中的数据的相关政策。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">二、</span><span>&nbsp;&nbsp; </span><span style="font-family: 宋体">信息收集范围</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">本隐私政策中涉及的个人信息包括：</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">基本信息：您为完成云鼎汇富平台账户的注册、管理、实名认证等，您提交的真实、合法、有效的个人姓名、联系地址、联系电话号码、电子邮箱等；</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">个人身份信息：您使用云鼎汇富平台域名注册、网站备案、云应用市场等服务时，您提交身份证、护照、回乡证、台胞证等；</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">网络身份标识信息：包括系统账号、密码、</span><span>IP</span><span style="font-family: 宋体">地址、邮箱地址等；</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">个人财产信息：您在云鼎汇富平台平台交易和消费记录、以及代金券等虚拟财产信息等；</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">个人上网记录：网站浏览记录、软件使用记录、日志信息等；</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">个人常用设备信息：包括硬件型号、操作系统类型、</span><span>IDFA</span><span style="font-family: 宋体">在内的描述个人常用设备基本情况的信息等；</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本隐私政策中涉及的个人敏感信息包括：您的财产信息（包括交易记录及代金券等虚拟财产信息）；个人身份信息（包括身份证、护照、回乡证、台胞证）；网络身份识别信息（包括账户名、邮箱地址及与前述有关的密码）；其他信息（包括个人电话号码、手机号码、网页浏览记录）。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">请您理解，我们向您提供的服务是不断更新和发展的。如果您选择使用的服务过程中涵盖以上未提及信息，基于该服务我们需要收集您的信息的，我们会通过页面提示、交互流程、协议约定等方式另行向您说明信息收集的范围与目的。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">三、</span><span>&nbsp;&nbsp; </span><span style="font-family: 宋体">如何收集信息</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.1&nbsp; </span><span style="font-family: 宋体">您申请成为云鼎汇富平台平台会员</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.1.1&nbsp; </span><span style="font-family: 宋体">当您在云鼎汇富平台网站创建云鼎汇富平台账户时，我们通过您主动提交的您在中华人民共和国境内的</span><span>&nbsp; </span><span style="font-family: 宋体">手机号码以便为您设置或生成平台账号。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.1.2&nbsp; </span><span style="font-family: 宋体">在您注册完成云鼎汇富平台账户后，您可以在账户“</span> <span style="font-family: 宋体">推广资料”一栏中继续填写您真实有效的信息，包括：</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">（</span><span>1</span><span style="font-family: 宋体">）您的</span> <span style="font-family: 宋体">主营行业、公司类型、注册资金、年营业额等；</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">（</span><span>2</span><span style="font-family: 宋体">）</span> <span style="font-family: 宋体">您的主营产品、经营模式、主营品牌、联系信息（所在地区、街道）等；</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">您若不提供这些信息，将会影响您对某些服务的使用。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.1.3&nbsp; </span><span style="font-family: 宋体">您的账号下创建子账号时，需要收集子账号主体的姓名、手机号码、电子邮箱。您提交的子账号的个人信息视为您已经获得子账号主体的授权并提交子账号的上述信息。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.1.4&nbsp; </span><span style="font-family: 宋体">如果您仅需使用浏览、搜索等基本服务，您不需要注册成为我们的会员及提供上述信息。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.2&nbsp; </span><span style="font-family: 宋体">您使用云鼎汇富平台服务</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.2.1&nbsp; </span><span style="font-family: 宋体">在您使用云鼎汇富平台的服务时，您通过您的账号提供您的真实身份信息以完成实名认证，在此过程中，您将提交您的姓名（单位主体名称）、身份证号码（营业执照号码）、企业注册地区、企业性质、企业名称和营业执照注册号，企业授权人信息</span> <span style="font-family: 宋体">等。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.2.2&nbsp; </span><span style="font-family: 宋体">在接受域名、备案等服务前，根据中华人民共和国法律，云鼎汇富平台将通过云鼎汇富平台网站页面向您展示您需要提交的信息，以届时网站页面展示为准，如果您不使用某些服务，则无需提供相关信息。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.2.3&nbsp; </span><span style="font-family: 宋体">您使用我们的服务后，我们还将根据您的需要，根据您提供的收件人的姓名、联系方式、收货地址，为您寄送合同及</span><span>/</span><span style="font-family: 宋体">或发票等。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.2.4&nbsp; </span><span style="font-family: 宋体">我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置等软硬件特征信息）、设备所在位置相关信息（</span><span>IP</span><span style="font-family: 宋体">地址）。我们可能会将前面两类信息进行关联，以便我们能在不同设备上为您提供一致的服务。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.2.5&nbsp; </span><span style="font-family: 宋体">当您使用我们的网站或客户端提供的服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、</span><span>IP</span><span style="font-family: 宋体">地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.2.6&nbsp; </span><span style="font-family: 宋体">请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做去标识化处理。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.2.7&nbsp; </span><span style="font-family: 宋体">用户账户的支持信息：基于您使用云鼎汇富平台服务而产生的用户的咨询记录、报障记录和针对用户故障的排障过程（如通信或通话记录），云鼎汇富平台将通过记录、分析这些信息以便更及时响应您的帮助请求，以及用于改进服务。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.3&nbsp; </span><span style="font-family: 宋体">我们可能会通过</span><span> Cookies </span><span style="font-family: 宋体">和其他相关技术收集和使用您的信息。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.3.1&nbsp; </span><span style="font-family: 宋体">我们使用</span><span> Cookies </span><span style="font-family: 宋体">的具体用途包括：</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">记住您的身份。例如：</span><span>Cookies </span><span style="font-family: 宋体">有助于我们辨认您作为我们的注册用户的身份。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">分析您使用我们服务的情况，以便为您提供更加周到的个性化服务，包括定制化页面、推荐等服务。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.3.2&nbsp; </span><span style="font-family: 宋体">您可以通过浏览器设置拒绝或管理</span><span> Cookies</span><span style="font-family: 宋体">。但请注意，如果停用</span><span> Cookies</span><span style="font-family: 宋体">，您有可能无法享受最佳的服务体验，某些功能的可用性可能会受到影响。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.3.3&nbsp; </span><span style="font-family: 宋体">通过云鼎汇富平台</span><span> Cookies </span><span style="font-family: 宋体">记录的有关信息，将适用本隐私声明。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.4&nbsp; </span><span style="font-family: 宋体">我们从第三方获得您个人信息的情形</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">我们可能从第三方获取您授权共享的账户信息，并在您同意本隐私政策后将您的第三方账户与您的云鼎汇富平台账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与</span><span>/</span><span style="font-family: 宋体">或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>3.5&nbsp; </span><span style="font-family: 宋体">当您终止使用我们的产品与</span><span>/</span><span style="font-family: 宋体">或服务时，或您使用我们的产品与</span><span>/</span><span style="font-family: 宋体">或服务之服务期届满后，我们将立即停止继续收集您的个人信息。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">四、</span><span>&nbsp;&nbsp; </span><span style="font-family: 宋体">信息使用</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">为了向您提供更加优质、便捷、安全的服务，在符合相关法律法规的前提下，我们可能将收集的信息用作以下用途：</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">向您提供服务。包括并不限于通过对您提供的数据进行分析，为您提供数据分析结果；同时也会将您的数据分析结果优化数据分析的算法从而再次为您或者其他用户的数据分析提供算法依据。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">满足您的个性化需求。例如，语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">服务优化和开发。例如，我们会根据云鼎汇富平台系统响应您的需求时产生的信息，优化我们的服务。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">保护云鼎汇富平台、云鼎汇富平台用户和云鼎汇富平台的合作伙伴。例如，我们会将您的信息用于身份验证、安全防范、投诉处理、纠纷协调、诈骗监测等用途。例如，您在使用安全功能或其他类似服务时，我们会对恶意程序或病毒进行检测，或为您识别诈骗信息。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">向您提供与您更加相关的服务。例如，向您提供您可能感兴趣的类似功能或服务等。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">邀请您参与有关我们产品和服务的调查。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">为预防、发现、调查欺诈、侵权、危害安全、非法或违反与平台或与平台关联公司的协议、政策或规则的行为，保护您、其他用户或公众，平台或平台关联公司的合法权益，平台可能使用或整合您的用户信息、服务使用信息、设备信息、日志信息以及平台关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">为向您提供更契合您需求的服务内容，平台会收集和使用您在访问或使用平台时的浏览、搜索记录、订单信息、您的兴趣爱好，提取您的偏好特征，平台也可能会将您的信息进行处理或者与来自其他服务的信息结合起来，进行数据分析以形成间接用户画像，用于向您提供更加个性化的服务，向您展示、推送您可能感兴趣的内容，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或者在征得您同意的情况下与本产品合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">其他可能需要使用收集的信息的相关场景，如果使用场景与初始场景无合理的关联性，我们会在使用信息前重新征得您的同意。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">五、</span><span>&nbsp;&nbsp; </span><span style="font-family: 宋体">信息披露</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">未经您同意，我们不会与任何无关第三方分享您的信息，以下情形除外：</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>5.1 </span><span style="font-family: 宋体">我们可能将您的信息与我们的关联公司、第三方服务提供商、承包商及代理分享，仅用作下列用途：</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">提供“二、信息收集范围”中的相应功能或服务所必需，以及出于“四、信息使用”中的部分所述目的所必需。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">履行我们在《服务协议》或本声明中的义务和行使我们的权利。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">如我们与任何上述第三方分享您的信息，我们将努力确保第三方在使用您的信息时遵守本声明及我们要求其遵守的其他适当的保密和安全措施。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>5.2 </span><span style="font-family: 宋体">随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的信息有可能作为此类交易的一部分而被转移。我们将遵守相关法律法规的要求，在转移前通知您，确保信息在转移时的机密性，以及变更后继续履行相应责任和义务。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>5.3 </span><span style="font-family: 宋体">我们还可能因以下原因而披露您的信息：</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">遵守适用的法律法规等有关规定。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">遵守法院判决、裁定或其他法律程序的规定。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">遵守相关政府机关或其他有权机关的要求。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">我们有理由确信需遵守法律法规等有关规定。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">为执行相关服务协议或本隐私声明、维护社会公共利益、处理投诉</span><span>/</span><span style="font-family: 宋体">纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">经过您合法授权的情形。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">如我们因上述原因而披露您的信息，我们将在遵守法律法规相关规定及本声明的基础上及时告知您。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">六、</span> <span style="font-family: 宋体">信息存储</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>6.1 </span><span style="font-family: 宋体">云鼎汇富平台收集的有关您的信息保存在云鼎汇富平台位于中国大陆的服务器。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>6.2 </span><span style="font-family: 宋体">通常，我们仅在为您提供服务期间保留您的信息，保留时间不会超过满足相关使用目的所必须的时间。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">遵守适用的法律法规等有关规定。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">遵守法院判决、裁定或其他法律程序的要求。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">遵守相关政府机关或其他有权机关的要求。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">我们有理由确信需遵守法律法规等有关规定。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">为执行相关服务协议或本隐私声明、维护社会公共利益、处理投诉</span><span>/</span><span style="font-family: 宋体">纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">七、</span> <span style="font-family: 宋体">信息安全</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>8.1 </span><span style="font-family: 宋体">我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">我们使用各种安全技术以保障信息的安全。例如，我们将通过服务器多备份、密码加密等安全措施，防止信息泄露、毁损、丢失。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">我们建立严格的管理制度和流程以保障信息的安全。例如，我们严格限制访问信息的人员范围，并进行审计，要求他们遵守保密义务。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">•</span> <span style="font-family: 宋体">我们重视信息安全合规工作，并通过众多国际和国内的</span> <span style="font-family: 宋体">安全认证，如</span><span> ISO 27018 </span><span style="font-family: 宋体">公有云个人信息保护认证、网络安全等级保护认证、</span><span>ISO 27001 </span><span style="font-family: 宋体">信息安全管理体系认证等，以业界先进的解决方案充分保障您的信息安全。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网环境下，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，按照《国家网络安全事件应急预案》等有关规定及时上报，并以发送邮件、推送通知、公告等形式告知您相关情况，并向您给出安全建议。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>8.2 </span><span style="font-family: 宋体">为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识。我们仅在云鼎汇富平台直接导致您个人信息泄露的范围内承担责任，因此，请您妥善保管您的账号及密码信息，避免您的个人信息泄露。云鼎汇富平台账户及</span><span> QQ </span><span style="font-family: 宋体">账号均有安全保护功能，但您也需要妥善保护自己的个人信息，除非您判断认为必要的情形下，不向任何第三人提供您的账号密码等个人信息。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">八、</span> <span style="font-family: 宋体">访问与控制</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>8.1 </span><span style="font-family: 宋体">您可以在使用我们服务的过程中，访问、修改和删除您的相关信息。您访问、修改和删除信息的方式将取决于您使用的具体服务。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>8.2 </span><span style="font-family: 宋体">如您发现我们违反法律法规的规定或者双方的约定收集、使用您的信息，您可以要求我们删除。如您发现我们收集、存储的您的信息有错误的，且无法自行更正的，您也可以要求我们更正。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>8.3 </span><span style="font-family: 宋体">在访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障账户安全。请您理解，由于技术所限、基于法律法规要求，您的某些请求可能无法进行响应。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>8.4 </span><span style="font-family: 宋体">基于法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>8.5 </span><span style="font-family: 宋体">您可以自行选择撤回对某些非基本功能或服务对您信息处理的同意，并通过联系客服的方式选择注销云鼎汇富平台账号。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>8.6 </span><span style="font-family: 宋体">如您对上述权利实现存在疑问，可以根据“十一、与我们联系”中的相关联系方式与我们取得联系。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">九、变更</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">我们可能适时修订本隐私声明的条款，该等修订构成本《隐私声明》的一部分。如可能造成您在本隐私声明下权利的实质减少或扩大收集、使用信息的范围等重要规则变更时，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本隐私声明的约束。</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span>&nbsp;</span>
</p>
<p style=";text-align: justify;font-size: 14px;font-family: Calibri, sans-serif;color: rgb(0, 0, 0);text-wrap: wrap">
    <span style="font-family: 宋体">十、</span> <span style="font-family: 宋体">其他</span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);text-wrap: wrap;font-size: 14px;font-family: 宋体">本产品收集及使用上述信息的目的是为了更好地经营本产品产品和服务（包括但不限于向您提供个性化的服务），平台将基于本政策未载明的其他特定目的收集您的信息时，会事先征求您的同意。如平台停止运营本产品产品或服务，平台将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</span>
</p>
<p>
    <br/>
</p>`,
    };
  },
};
</script>
